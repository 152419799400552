.PhoneSliderContainer {
	display: flex;
	justify-content: center;

	.PhoneSliderContainer__content {
		width: 100%;
		.PhoneSliderContainer__swiper {
			.PhoneSliderContainer__swiper_slide {
				display: flex;
				justify-content: center;

				.PhoneSliderContainer__swiper_slide__phone {
					width: 36.9em;
					height: 74.5em;
					.PhoneSliderContainer__swiper_slide__phone_image {
						background-size: contain;
						background-repeat: no-repeat;
						background-position: 100% 100%;
						width: 100%;
						height: 100%;

						&.image_1 {
							background-image: url('../../img/iphone1.png');
						}
						&.image_2 {
							background-image: url('../../img/iphone2.png');
						}
						&.image_3 {
							background-image: url('../../img/iphone3.png');
						}
					}
				}

				.PhoneSliderContainer__swiper_slide__text {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.PhoneSliderContainer__swiper_slide__title {
						color: var(--gray-1100, #353738);
						font-weight: bold;
						font-size: 4.5em;
						margin-bottom: 0.32em;
					}

					.PhoneSliderContainer__swiper_slide__desc {
						color: var(--gray-1000, #4B4D4F);
						text-shadow: 0 0.4em 0.4em rgba(0, 0, 0, 0.25);
						font-family: "Montserrat", serif;
						font-size: 1.8em;
						font-weight: 500;
						line-height: 140%;
					}
				}
			}
		}
	}
}

.desktop {
	.PhoneSliderContainer {
		.PhoneSliderContainer__content {
			.PhoneSliderContainer__swiper {
				width: 130em;

				.PhoneSliderContainer__swiper_slide {
					.PhoneSliderContainer__swiper_slide__phone {
						margin-right: 11.2em;
					}

					.PhoneSliderContainer__swiper_slide__text {
						max-width: 65.7em;
					}
				}
			}
		}
	}
}

.phone {
	.PhoneSliderContainer {
		margin-top: 6em;

		.PhoneSliderContainer__content {
			.PhoneSliderContainer__swiper {
				.PhoneSliderContainer__swiper_slide {
					flex-direction: column;
					align-items: center;

					.PhoneSliderContainer__swiper_slide__phone {
						order: 1;
					}

					.PhoneSliderContainer__swiper_slide__text {
						margin-top: 2em;
						margin-bottom: 5em;
						order: 0;

						.PhoneSliderContainer__swiper_slide__desc {
							font-size: 2em;
						}
					}
				}
			}
		}
	}
}