.PresentationContainer {
	display: flex;
	justify-content: center;
	background: linear-gradient(180deg, #FFFEFC -55.43%, #FFF8F0 100%);
	padding: 8em 0;

	&__cards {
		display: flex;
		justify-content: center;
		margin-top: 8em;
	}

	&__video {
		display: flex;
		justify-content: center;

		&_content {
			width: 100%;
			height: 41.5em;
			background: #000;
		}
	}
}

.desktop {
	.PresentationContainer {
		&__video {
			&_content {
				width: 66em;
			}
		}
	}
}

.phone {
	.PresentationContainer {
		width: 100%;

		&__content {
			width: 100%;
			padding: 0 2em;
		}

		&__cards {
			flex-direction: column;
			align-items: center;
		}

		&__video {
			padding: 0 2em;
		}
	}
}