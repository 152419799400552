.BubbleContainer {
	width: 100%;
	background: linear-gradient(275deg, #FFECD7 9.5%, rgba(255, 236, 215, 0.00) 100.79%);
	display: flex;
	justify-content: center;
	position: relative;

	.BubbleContainer__content {
		width: 114em;
		height: 100%;
		display: flex;
		align-items: center;
		overflow: hidden;

		.BubbleContainer__bubbles {
			position: relative;

			.BubbleContainer__bubble__image {
				height: 55em;
				width: 55em;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 35% 100%;
				background-image: url('../../img/StockedImageLaugh.jpg');
				border-radius: 50%;
			}

			&:after {
				content: '';
				background-color: #FFA99C;
				background-size: cover;
				background-repeat: no-repeat;
				border-radius: 50%;
				width: 23.1em;
				height: 23.1em;
				position: absolute;
				bottom: -6em;
			}
		}

		.BubbleContainer__try {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.BubbleContainer__text {
				color: var(--theme-primary-indigo, #5022E2);
				font-size: 4em;
				font-weight: 700;
				max-width: 13.55em;
				margin-bottom: 1em;
			}
		}
	}
}

.desktop {
	.BubbleContainer {
		height: 35.3em;

		.BubbleContainer__content {
			.BubbleContainer__bubbles {
				margin-right: 6.3em;
			}
		}
	}
}

.phone {
	.BubbleContainer {
		.BubbleContainer__content {
			flex-direction: column;
			padding: 3.5em 2em;

			.BubbleContainer__bubbles {
				&:after {
					z-index: -1;
				}
			}

			.BubbleContainer__try {
				align-items: center;
			}

			.BubbleContainer__text {
				font-size: 6em;
				line-height: 1.3em;
				margin-bottom: 0.5em;
			}

			.BubbleContainer__button {
				font-size: 1.3em;
				width: 100%;
				justify-content: center;
			}
		}
	}
}