.Header {
	height: 7em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: absolute;
	top: 0;
	width: 100%;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.1);

	&__content {
		display: flex;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		padding: 0 10.5em;
	}

	&__logo {
		font-size: 3em;
		font-family: 'Exo', sans-serif;
		font-weight: 900;
		color: #6152FF;
	}

	&__button {
		padding: 1.6em;
	}

	&__menu_items {
		margin: 0 2em;
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		max-width: 500px;
		flex-wrap: wrap;

		.Header__menu_item {
			font-size: 1.8em;
			border: none;
			padding: 0;
			background: none;
			font-weight: 700;
			color: #85888C;
			cursor: pointer;
			transition: color 0.5s linear;

			&:hover {
				color: #000;
			}

			&:not(:last-child) {
				margin-right: 2em;
			}
		}
	}

	&__mobile_menu {
		position: absolute;
		width: 100%;
		top: 100%;
		background: rgb(245, 246, 246);
		box-shadow: 0 4px 2px -2px rgba(36, 36, 36, 0.37);
		transition: all 0.4s ease;
		left:-100%;

		&.opened {
			left: 0;
		}

		.Header__menu_items {
			flex-direction: column;
			align-items: flex-start;

			.Header__menu_item {
				margin: 20px 0;
				font-size: 14px;

				.Header__button {
					font-size: 8px;
				}
			}
		}
	}

	&__burger {
		position: relative;
		width: 40px;
		height: 35px;
		display: block;
		margin-left: 10px;
		background: none;
	}

	&__burger span, &__burger:after, &__burger:before{
		height: 3px;
		width: 100%;
		position: absolute;
		background: #515758;
		margin: 0 auto;
		left: 0;
	}

	&__burger span{
		top: 16px;
	}

	&__burger:after, &__burger:before{
		content: '';
	}

	&__burger:after{
		bottom: 5px;
	}

	&__burger:before{
		top: 5px;
	}

	&__burger.opened span {
		opacity:0;
		transition: 0.5s;
	}

	&__burger.opened:before {
		transform: rotate(38deg);
		top: 16px;
		transition: 0.4s;
	}

	&__burger.opened:after {
		transform: rotate(-38deg);
		bottom: 16px;
		transition: 0.4s;
	}
}

.phone {
	.Header {
		height: 9em;

		&__content {
			padding: 0 2em;
		}
	}
}
