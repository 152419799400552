@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;700&family=Exo:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@300;400;700&family=Exo:wght@400;700;900&family=Montserrat&display=swap');

* {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
	outline: none;
	font-family: 'Exo 2', Arial, Tahoma, Verdana, sans-serif;
	line-height: 140%;
}

html {
	height: 101%;
	font-size: 16px;
	scroll-behavior: smooth;
}

/* always display scrollbars */
body {
	font-size: 62.5%;
	line-height: 1;
	box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
	padding-left: 25px;
	position: relative;

	li {
		position: relative;

		&:before {
			content: '';
			background-size: contain;
			width: 15px;
			height: 15px;
			position: absolute;
			left: -26px;
			top: 6px;
			background: url("../img/icons/play.svg") no-repeat 100% 100%;
		}
	}
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
}

strong {
	font-weight: bold;
}

input {
	outline: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	border: 0;
	max-width: 100%;
}

a {
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.Block {
	width: 100%;

	&:not(.maxWidth) {
		width: 114em;
	}
	&:not(:first-child) {
		margin-bottom: 10em;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.app.phone {
	overflow: hidden;
	font-size: 0.65em;
	.content {
		padding-top: 12em;
	}

	.Block {
		&:not(.maxWidth) {
			width: 100%;

			.Block__content {
				padding: 0 2em;
			}
		}

		&:last-child {
			margin-bottom: 5em;
		}
	}
}