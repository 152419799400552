.Card {
	box-shadow: 0 41px 74px 0 #FFECD6, 0 4px 15px 0 rgba(255, 230, 200, 0.66);
	padding: 2em;
	border-radius: 1em;

	.Icon {
		width: 4em;
		height: 4em;
		padding: 1.5em;
		border-radius: 17px;
		background: linear-gradient(141deg, rgba(254, 154, 82, 0.10) 25.69%, rgba(255, 87, 72, 0.10) 143.86%);
	}

	&__title {
		color: var(--gray-1100, #353738);
		font-weight: 700;
		font-size: 2.2em;
		margin-top: 2.2em;
		margin-bottom: 1em;
	}

	&__desc {
		font-size: 1.8em;
	}
}

.desktop {
	.Card {
		max-width: 28.5em;

		&:not(:first-child) {
			margin-left: 8.3em;
		}
	}
}

.phone {
	.Card {
		width: 38em;
		font-size: 1.3em;

		&:not(:first-child) {
			margin-top: 5em;
		}
	}
}