.FaqContainer {
	display: flex;
	justify-content: center;

	&__content {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	&__title {
		color: var(--gray-1100, #353738);
		font-size: 4.5em;
		font-weight: 700;
		margin-bottom: 64px;
	}
}