.SubscriptionsContainer {
	&__title {
		color: var(--gray-1100, #353738);
		font-size: 4em;
		font-weight: 700;
		text-align: center;
	}

	&__cards {
		margin-top: 4em;
		display: flex;
		justify-content: center;
	}

	.switch-container {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 7em;
	}
	.switch
	{
		width: 65%;
		border-radius: 1em;
		border: 2px solid rgba(0, 0, 0, 0.06);

		position: relative;
		height:50px;
		display:flex;
		justify-content:space-around;

		input[type="radio"],input[type="checkbox"]{
			display:none;
			cursor: pointer;
		}
		label{
			cursor: pointer;
		}
	}
	.switch label{
		padding: 1rem;
		z-index:1;
		font-family: 'Exo 2', sans-serif;
		font-size: 1.5em;
		font-style: normal;
		font-weight: 700;
		line-height: 100%;
		letter-spacing: 1.125px;
		text-transform: uppercase;
		color: var(--gray-1100, #353738);
	}
	#active-label{
		position: absolute;
		top: 0;
		left:0;
		width: 34%;
		height: 100%;
		opacity: .5;
		transition: .5s left ease;
		background: var(--gradient-2, linear-gradient(95deg, #FE9A52 0.04%, #FF5748 100.04%));

		border-radius: 1em;
	}
	#switch-radio1:checked ~ #active-label{
		left:0;
	}
	#switch-radio2:checked ~ #active-label{
		left:33%;
	}
	#switch-radio3:checked ~ #active-label {
		left:66%;
	}
}

.phone {
	.SubscriptionsContainer {
		&__cards {
			margin-top: 4em;
			flex-direction: column;
			align-items: center;
		}

		.switch {
			width: 100%;
			align-items: center;
		}
	}
}