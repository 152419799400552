.Header {
  height: 7em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 100%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.Header__content {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10.5em;
}
.Header__logo {
  font-size: 3em;
  font-family: 'Exo', sans-serif;
  font-weight: 900;
  color: #6152FF;
}
.Header__button {
  padding: 1.6em;
}
.Header__menu_items {
  margin: 0 2em;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  max-width: 500px;
  flex-wrap: wrap;
}
.Header__menu_items .Header__menu_item {
  font-size: 1.8em;
  border: none;
  padding: 0;
  background: none;
  font-weight: 700;
  color: #85888C;
  cursor: pointer;
  transition: color 0.5s linear;
}
.Header__menu_items .Header__menu_item:hover {
  color: #000;
}
.Header__menu_items .Header__menu_item:not(:last-child) {
  margin-right: 2em;
}
.Header__mobile_menu {
  position: absolute;
  width: 100%;
  top: 100%;
  background: #f5f6f6;
  box-shadow: 0 4px 2px -2px rgba(36, 36, 36, 0.37);
  transition: all 0.4s ease;
  left: -100%;
}
.Header__mobile_menu.opened {
  left: 0;
}
.Header__mobile_menu .Header__menu_items {
  flex-direction: column;
  align-items: flex-start;
}
.Header__mobile_menu .Header__menu_items .Header__menu_item {
  margin: 20px 0;
  font-size: 14px;
}
.Header__mobile_menu .Header__menu_items .Header__menu_item .Header__button {
  font-size: 8px;
}
.Header__burger {
  position: relative;
  width: 40px;
  height: 35px;
  display: block;
  margin-left: 10px;
  background: none;
}
.Header__burger span,
.Header__burger:after,
.Header__burger:before {
  height: 3px;
  width: 100%;
  position: absolute;
  background: #515758;
  margin: 0 auto;
  left: 0;
}
.Header__burger span {
  top: 16px;
}
.Header__burger:after,
.Header__burger:before {
  content: '';
}
.Header__burger:after {
  bottom: 5px;
}
.Header__burger:before {
  top: 5px;
}
.Header__burger.opened span {
  opacity: 0;
  transition: 0.5s;
}
.Header__burger.opened:before {
  transform: rotate(38deg);
  top: 16px;
  transition: 0.4s;
}
.Header__burger.opened:after {
  transform: rotate(-38deg);
  bottom: 16px;
  transition: 0.4s;
}
.phone .Header {
  height: 9em;
}
.phone .Header__content {
  padding: 0 2em;
}
.Button {
  position: relative;
  border-radius: 0.25em;
  box-shadow: 0 0.25em 0.25em 0 rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 2em 4em;
  align-items: flex-start;
  gap: 0.625em;
  cursor: pointer;
}
.Button.contained {
  background: var(--gradient-2, linear-gradient(95deg, #FE9A52 0.04%, #FF5748 100.04%));
}
.Button.outlined {
  background: none;
  border: 1px solid var(--theme-warning, #FE9A52);
}
.Button.outlined .Button__content .Button__text {
  color: var(--theme-danger, #FD7D72);
}
.Button .Button__content .Button__text {
  color: var(--theme-white, #FFF);
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 15px */
  letter-spacing: 1.125px;
  text-transform: uppercase;
}
.Button.useIcon {
  padding-left: 6.5em;
}
.Button.useIcon .Button__content .Icon {
  position: absolute;
  left: 4em;
  top: 50%;
  transform: translateY(-50%);
}
.WelcomeContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
}
.WelcomeContainer .WelcomeContainer__content {
  display: flex;
  justify-content: center;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__title h1 {
  font-size: 5.4em;
  color: var(--theme-primary-indigo, #5022E2);
  font-weight: 700;
  line-height: 120%;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__descr {
  margin-top: 1.23em;
  color: var(--gray-1000, #4B4D4F);
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Montserrat", serif;
  font-size: 1.83em;
  width: 30.5em;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__buttons {
  display: flex;
  margin-top: 5.8em;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__buttons .WelcomeContainer__left_items__button:not(:last-child) {
  margin-right: 1.6em;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__right_items {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2em;
}
.WelcomeContainer .WelcomeContainer__content .WelcomeContainer__right_items .WelcomeContainer__right_items__image {
  width: 52.5em;
  height: 30.5em;
}
.desktop .WelcomeContainer {
  height: 100vh;
}
.desktop .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items {
  margin-right: 2em;
  max-width: 59.1em;
}
.phone .WelcomeContainer .WelcomeContainer__content {
  flex-direction: column;
  align-items: center;
}
.phone .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items {
  margin-bottom: 4em;
  width: 100%;
}
.phone .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__buttons {
  justify-content: space-between;
}
.phone .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__left_items .WelcomeContainer__left_items__descr {
  font-size: 2em;
}
.phone .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__right_items {
  width: 100%;
}
.phone .WelcomeContainer .WelcomeContainer__content .WelcomeContainer__right_items .WelcomeContainer__right_items__image {
  width: 100%;
  height: 30%;
}
.PhoneSliderContainer {
  display: flex;
  justify-content: center;
}
.PhoneSliderContainer .PhoneSliderContainer__content {
  width: 100%;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide {
  display: flex;
  justify-content: center;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone {
  width: 36.9em;
  height: 74.5em;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone .PhoneSliderContainer__swiper_slide__phone_image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  width: 100%;
  height: 100%;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone .PhoneSliderContainer__swiper_slide__phone_image.image_1 {
  background-image: url('../../img/iphone1.png');
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone .PhoneSliderContainer__swiper_slide__phone_image.image_2 {
  background-image: url('../../img/iphone2.png');
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone .PhoneSliderContainer__swiper_slide__phone_image.image_3 {
  background-image: url('../../img/iphone3.png');
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text .PhoneSliderContainer__swiper_slide__title {
  color: var(--gray-1100, #353738);
  font-weight: bold;
  font-size: 4.5em;
  margin-bottom: 0.32em;
}
.PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text .PhoneSliderContainer__swiper_slide__desc {
  color: var(--gray-1000, #4B4D4F);
  text-shadow: 0 0.4em 0.4em rgba(0, 0, 0, 0.25);
  font-family: "Montserrat", serif;
  font-size: 1.8em;
  font-weight: 500;
  line-height: 140%;
}
.desktop .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper {
  width: 130em;
}
.desktop .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone {
  margin-right: 11.2em;
}
.desktop .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text {
  max-width: 65.7em;
}
.phone .PhoneSliderContainer {
  margin-top: 6em;
}
.phone .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide {
  flex-direction: column;
  align-items: center;
}
.phone .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__phone {
  order: 1;
}
.phone .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text {
  margin-top: 2em;
  margin-bottom: 5em;
  order: 0;
}
.phone .PhoneSliderContainer .PhoneSliderContainer__content .PhoneSliderContainer__swiper .PhoneSliderContainer__swiper_slide .PhoneSliderContainer__swiper_slide__text .PhoneSliderContainer__swiper_slide__desc {
  font-size: 2em;
}
.BubbleContainer {
  width: 100%;
  background: linear-gradient(275deg, #FFECD7 9.5%, rgba(255, 236, 215, 0) 100.79%);
  display: flex;
  justify-content: center;
  position: relative;
}
.BubbleContainer .BubbleContainer__content {
  width: 114em;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.BubbleContainer .BubbleContainer__content .BubbleContainer__bubbles {
  position: relative;
}
.BubbleContainer .BubbleContainer__content .BubbleContainer__bubbles .BubbleContainer__bubble__image {
  height: 55em;
  width: 55em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 35% 100%;
  background-image: url('../../img/StockedImageLaugh.jpg');
  border-radius: 50%;
}
.BubbleContainer .BubbleContainer__content .BubbleContainer__bubbles:after {
  content: '';
  background-color: #FFA99C;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  width: 23.1em;
  height: 23.1em;
  position: absolute;
  bottom: -6em;
}
.BubbleContainer .BubbleContainer__content .BubbleContainer__try {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.BubbleContainer .BubbleContainer__content .BubbleContainer__try .BubbleContainer__text {
  color: var(--theme-primary-indigo, #5022E2);
  font-size: 4em;
  font-weight: 700;
  max-width: 13.55em;
  margin-bottom: 1em;
}
.desktop .BubbleContainer {
  height: 35.3em;
}
.desktop .BubbleContainer .BubbleContainer__content .BubbleContainer__bubbles {
  margin-right: 6.3em;
}
.phone .BubbleContainer .BubbleContainer__content {
  flex-direction: column;
  padding: 3.5em 2em;
}
.phone .BubbleContainer .BubbleContainer__content .BubbleContainer__bubbles:after {
  z-index: -1;
}
.phone .BubbleContainer .BubbleContainer__content .BubbleContainer__try {
  align-items: center;
}
.phone .BubbleContainer .BubbleContainer__content .BubbleContainer__text {
  font-size: 6em;
  line-height: 1.3em;
  margin-bottom: 0.5em;
}
.phone .BubbleContainer .BubbleContainer__content .BubbleContainer__button {
  font-size: 1.3em;
  width: 100%;
  justify-content: center;
}
.HowItWorksContainer {
  display: flex;
  justify-content: center;
}
.HowItWorksContainer__content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.HowItWorksContainer__title {
  color: var(--gray-1100, #353738);
  font-size: 4.5em;
  font-weight: 700;
}
.HowItWorksContainer__cards {
  display: flex;
  justify-content: center;
  margin-top: 8em;
}
.phone .HowItWorksContainer__cards {
  flex-direction: column;
  align-items: center;
}
.PresentationContainer {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #FFFEFC -55.43%, #FFF8F0 100%);
  padding: 8em 0;
}
.PresentationContainer__cards {
  display: flex;
  justify-content: center;
  margin-top: 8em;
}
.PresentationContainer__video {
  display: flex;
  justify-content: center;
}
.PresentationContainer__video_content {
  width: 100%;
  height: 41.5em;
  background: #000;
}
.desktop .PresentationContainer__video_content {
  width: 66em;
}
.phone .PresentationContainer {
  width: 100%;
}
.phone .PresentationContainer__content {
  width: 100%;
  padding: 0 2em;
}
.phone .PresentationContainer__cards {
  flex-direction: column;
  align-items: center;
}
.phone .PresentationContainer__video {
  padding: 0 2em;
}
.Card {
  box-shadow: 0 41px 74px 0 #FFECD6, 0 4px 15px 0 rgba(255, 230, 200, 0.66);
  padding: 2em;
  border-radius: 1em;
}
.Card .Icon {
  width: 4em;
  height: 4em;
  padding: 1.5em;
  border-radius: 17px;
  background: linear-gradient(141deg, rgba(254, 154, 82, 0.1) 25.69%, rgba(255, 87, 72, 0.1) 143.86%);
}
.Card__title {
  color: var(--gray-1100, #353738);
  font-weight: 700;
  font-size: 2.2em;
  margin-top: 2.2em;
  margin-bottom: 1em;
}
.Card__desc {
  font-size: 1.8em;
}
.desktop .Card {
  max-width: 28.5em;
}
.desktop .Card:not(:first-child) {
  margin-left: 8.3em;
}
.phone .Card {
  width: 38em;
  font-size: 1.3em;
}
.phone .Card:not(:first-child) {
  margin-top: 5em;
}
.SubscriptionsContainer__title {
  color: var(--gray-1100, #353738);
  font-size: 4em;
  font-weight: 700;
  text-align: center;
}
.SubscriptionsContainer__cards {
  margin-top: 4em;
  display: flex;
  justify-content: center;
}
.SubscriptionsContainer .switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7em;
}
.SubscriptionsContainer .switch {
  width: 65%;
  border-radius: 1em;
  border: 2px solid rgba(0, 0, 0, 0.06);
  position: relative;
  height: 50px;
  display: flex;
  justify-content: space-around;
}
.SubscriptionsContainer .switch input[type="radio"],
.SubscriptionsContainer .switch input[type="checkbox"] {
  display: none;
  cursor: pointer;
}
.SubscriptionsContainer .switch label {
  cursor: pointer;
}
.SubscriptionsContainer .switch label {
  padding: 1rem;
  z-index: 1;
  font-family: 'Exo 2', sans-serif;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 1.125px;
  text-transform: uppercase;
  color: var(--gray-1100, #353738);
}
.SubscriptionsContainer #active-label {
  position: absolute;
  top: 0;
  left: 0;
  width: 34%;
  height: 100%;
  opacity: 0.5;
  transition: 0.5s left ease;
  background: var(--gradient-2, linear-gradient(95deg, #FE9A52 0.04%, #FF5748 100.04%));
  border-radius: 1em;
}
.SubscriptionsContainer #switch-radio1:checked ~ #active-label {
  left: 0;
}
.SubscriptionsContainer #switch-radio2:checked ~ #active-label {
  left: 33%;
}
.SubscriptionsContainer #switch-radio3:checked ~ #active-label {
  left: 66%;
}
.phone .SubscriptionsContainer__cards {
  margin-top: 4em;
  flex-direction: column;
  align-items: center;
}
.phone .SubscriptionsContainer .switch {
  width: 100%;
  align-items: center;
}
.SubscriptionsContainer__test_cards .SubscriptionCard {
  box-shadow: 0 41px 74px 0 #FFECD6, 0 4px 15px 0 rgba(255, 230, 200, 0.66);
}
.SubscriptionCard {
  border-radius: 1em;
  padding: 2em;
  border: 2px solid rgba(0, 0, 0, 0.06);
}
.SubscriptionCard:nth-child(2) {
  box-shadow: 0 41px 74px 0 #FFECD6, 0 4px 15px 0 rgba(255, 230, 200, 0.66);
}
.SubscriptionCard__top_block {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 2em;
}
.SubscriptionCard__bottom_block {
  padding-top: 2em;
}
.SubscriptionCard__title {
  color: var(--gray-1100, #353738);
  font-weight: 700;
  font-size: 2.2em;
  margin-bottom: 0.5em;
}
.SubscriptionCard__desc {
  font-size: 1.8em;
}
.SubscriptionCard__cost {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 1.2em;
}
.SubscriptionCard__cost_price {
  font-size: 2.5em;
  margin-right: 0.35em;
  line-height: normal;
}
.SubscriptionCard__cost_price.default {
  font-weight: 700;
  text-decoration: line-through;
}
.SubscriptionCard__cost_desc {
  font-size: 1.4em;
}
.SubscriptionCard__bonuses {
  margin-bottom: 2em;
}
.SubscriptionCard__bonus {
  padding-left: 4em;
  font-size: 1.2em;
  margin-bottom: 1em;
  position: relative;
}
.SubscriptionCard__bonus:before {
  content: '';
  background-color: #ffe4d9;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  position: absolute;
  left: 1em;
}
.SubscriptionCard__bonus:after {
  content: '';
  background-image: url("../../img/icons/check.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  position: absolute;
  left: 14px;
  top: 2px;
  width: 14px;
  height: 14px;
}
.desktop .SubscriptionCard {
  max-width: 28.5em;
}
.desktop .SubscriptionCard:not(:first-child) {
  margin-left: 8.3em;
}
.phone .SubscriptionCard {
  width: 38em;
  font-size: 1.3em;
}
.phone .SubscriptionCard:not(:first-child) {
  margin-top: 5em;
}
.phone .SubscriptionCard__bonus:after {
  top: 0;
  left: 12px;
}
.phone .SubscriptionCard .Button {
  width: 100%;
  justify-content: center;
}
.FaqContainer {
  display: flex;
  justify-content: center;
}
.FaqContainer__content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.FaqContainer__title {
  color: var(--gray-1100, #353738);
  font-size: 4.5em;
  font-weight: 700;
  margin-bottom: 64px;
}
.AccordionItem {
  border-top: 1px solid #CED3D9;
  padding: 4.8em 0;
}
.AccordionItem:last-child {
  border-bottom: 1px solid #CED3D9;
}
.AccordionItem .button {
  font-size: 1.8em;
  font-weight: 700;
  background: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.AccordionItem .button:hover {
  cursor: pointer;
}
.AccordionItem .button .control {
  font-size: 35px;
  line-height: 20px;
  transition: ease 0.2s;
  height: 11px;
  width: 16px;
}
.AccordionItem.clicked .control {
  transform: rotate(180deg);
}
.AccordionItem__answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
}
.AccordionItem__answer {
  font-size: 1.8em;
  font-family: "Montserrat", serif;
  white-space: pre-wrap;
}
.phone .AccordionItem {
  font-size: 1.3em;
}
.ContactBubbleContainer {
  width: 100%;
  background: linear-gradient(209deg, #FE9A52 -2.93%, #FF5748 88.81%);
  display: flex;
  justify-content: center;
  position: relative;
}
.ContactBubbleContainer .ContactBubbleContainer__content {
  width: 114em;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles {
  position: relative;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles .ContactBubbleContainer__bubble__image {
  height: 55em;
  width: 55em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 35% 100%;
  background-image: url('../../img/StockedImageContact.jpeg');
  border-radius: 50%;
  position: relative;
  z-index: 1;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles:after,
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles:before {
  content: '';
  background-size: cover;
  background: linear-gradient(0deg, #FFC69D 44.73%, #FF7F74 146.59%) no-repeat;
  border-radius: 50%;
  width: 23.1em;
  height: 23.1em;
  position: absolute;
  z-index: 0;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles:after {
  bottom: -4em;
  right: -7em;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles:before {
  top: 8em;
  left: 0;
  width: 10em;
  height: 10em;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 65%;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__text {
  color: #fff;
  font-size: 4em;
  font-weight: 700;
  max-width: 13.55em;
  margin-bottom: 1em;
  line-height: 25px;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact {
  color: #ffffff;
  font-size: 2em;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact_title {
  font-weight: bold;
  font-size: 1.25em;
  position: relative;
  padding-left: 40px;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact_title:before {
  content: '';
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  top: 3px;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact.email .ContactBubbleContainer__contact_title:before {
  background: url("../../img/icons/email.svg") no-repeat 100% 100%;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact.telegram .ContactBubbleContainer__contact_title:before {
  background: url("../../img/icons/telegram.svg") no-repeat 100% 100%;
}
.ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block .ContactBubbleContainer__contact:not(:last-child) {
  margin-bottom: 1.5em;
}
.desktop .ContactBubbleContainer {
  height: 35.3em;
}
.desktop .ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles {
  margin-right: 6.3em;
}
.phone .ContactBubbleContainer .ContactBubbleContainer__content {
  flex-direction: column;
  padding: 3.5em 2em;
}
.phone .ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__bubbles:after {
  z-index: -1;
}
.phone .ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__try {
  align-items: center;
}
.phone .ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__text {
  font-size: 6em;
  line-height: 1.3em;
  margin-bottom: 0.5em;
}
.phone .ContactBubbleContainer .ContactBubbleContainer__content .ContactBubbleContainer__contact_block {
  font-size: 1.3em;
  width: 100%;
  justify-content: center;
}
.FooterContainer {
  border-top: 1px solid #E1E6ED;
  font-size: 15px;
  color: #7E92B2;
  padding-top: 20px;
}
.FooterContainer__content {
  display: flex;
  justify-content: space-between;
}
.FooterContainer__content .bold {
  color: var(--blue-gray-500, #6C7E99);
  font-weight: 700;
}
.FooterContainer__content .love {
  color: var(--theme-danger, #EB6453);
}
.phone .FooterContainer .FooterContainer__content {
  flex-direction: column;
  align-items: center;
}
.phone .FooterContainer .FooterContainer__content .FooterContainer__left_items {
  margin-bottom: 1em;
}
