.ContactBubbleContainer {
	width: 100%;
	background: linear-gradient(209deg, #FE9A52 -2.93%, #FF5748 88.81%);
	display: flex;
	justify-content: center;
	position: relative;

	.ContactBubbleContainer__content {
		width: 114em;
		height: 100%;
		display: flex;
		align-items: center;
		overflow: hidden;

		.ContactBubbleContainer__bubbles {
			position: relative;

			.ContactBubbleContainer__bubble__image {
				height: 55em;
				width: 55em;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: 35% 100%;
				background-image: url('../../img/StockedImageContact.jpeg');
				border-radius: 50%;
				position: relative;
				z-index: 1;
			}

			&:after, &:before {
				content: '';
				background-size: cover;
				background: linear-gradient(0deg, #FFC69D 44.73%, #FF7F74 146.59%) no-repeat;
				border-radius: 50%;
				width: 23.1em;
				height: 23.1em;
				position: absolute;
				z-index: 0;
			}

			&:after {
				bottom: -4em;
				right: -7em;
			}

			&:before {
				top: 8em;
				left: 0;
				width: 10em;
				height: 10em;
			}
		}

		.ContactBubbleContainer__contact_block {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			height: 65%;

			.ContactBubbleContainer__text {
				color: #fff;
				font-size: 4em;
				font-weight: 700;
				max-width: 13.55em;
				margin-bottom: 1em;
				line-height: 25px;
			}

			.ContactBubbleContainer__contact {
				color: #ffffff;
				font-size: 2em;

				&_title {
					font-weight: bold;
					font-size: 1.25em;
					position: relative;
					padding-left: 40px;

					&:before {
						content: '';
						background-size: contain;
						width: 30px;
						height: 30px;
						position: absolute;
						left: 0;
						top: 3px;
					}
				}

				&.email {
					.ContactBubbleContainer__contact_title {
						&:before {
							background: url("../../img/icons/email.svg") no-repeat 100% 100%;

						}
					}
				}

				&.telegram {
					.ContactBubbleContainer__contact_title {
						&:before {
							background: url("../../img/icons/telegram.svg") no-repeat 100% 100%;

						}
					}
				}

				&:not(:last-child) {
					margin-bottom: 1.5em;
				}
			}
		}
	}
}

.desktop {
	.ContactBubbleContainer {
		height: 35.3em;

		.ContactBubbleContainer__content {
			.ContactBubbleContainer__bubbles {
				margin-right: 6.3em;
			}
		}
	}
}

.phone {
	.ContactBubbleContainer {
		.ContactBubbleContainer__content {
			flex-direction: column;
			padding: 3.5em 2em;

			.ContactBubbleContainer__bubbles {
				&:after {
					z-index: -1;
				}
			}

			.ContactBubbleContainer__try {
				align-items: center;
			}

			.ContactBubbleContainer__text {
				font-size: 6em;
				line-height: 1.3em;
				margin-bottom: 0.5em;
			}

			.ContactBubbleContainer__contact_block {
				font-size: 1.3em;
				width: 100%;
				justify-content: center;
			}
		}
	}
}