.FooterContainer {
	border-top: 1px solid #E1E6ED;
	font-size: 15px;
	color: #7E92B2;
	padding-top: 20px;

	&__content {
		display: flex;
		justify-content: space-between;

		.bold {
			color: var(--blue-gray-500, #6C7E99);
			font-weight: 700;
		}

		.love {
			color: var(--theme-danger, #EB6453);
		}
	}
}

.phone {
	.FooterContainer {
		.FooterContainer__content {
			flex-direction: column;
			align-items: center;

			.FooterContainer__left_items {
				margin-bottom: 1em;
			}
		}
	}
}