.WelcomeContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: auto;


	.WelcomeContainer__content {
		display: flex;
		justify-content: center;
		.WelcomeContainer__left_items {
			.WelcomeContainer__left_items__title {
				h1 {
					font-size: 5.4em;
					color: var(--theme-primary-indigo, #5022E2);
					font-weight: 700;
					line-height: 120%;
				}
			}

			.WelcomeContainer__left_items__descr {
				margin-top: 1.23em;
				color: var(--gray-1000, #4B4D4F);
				text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
				font-family: "Montserrat", serif;
				font-size: 1.83em;
				width: 30.5em;
			}

			.WelcomeContainer__left_items__buttons {
				display: flex;
				margin-top: 5.8em;

				.WelcomeContainer__left_items__button {
					&:not(:last-child) {
						margin-right: 1.6em;
					}
				}
			}
		}
		.WelcomeContainer__right_items {
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 2em;

			.WelcomeContainer__right_items__image {
				width: 52.5em;
				height: 30.5em;
			}
		}
	}
}

.desktop {
	.WelcomeContainer {
		height: 100vh;

		.WelcomeContainer__content {
			.WelcomeContainer__left_items {
				margin-right: 2em;
				max-width: 59.1em;
			}
		}
	}
}


.phone {
	.WelcomeContainer {
		.WelcomeContainer__content {
			flex-direction: column;
			align-items: center;

			.WelcomeContainer__left_items {
				margin-bottom: 4em;
				width: 100%;

				.WelcomeContainer__left_items__buttons {
					justify-content: space-between;
				}

				.WelcomeContainer__left_items__descr {
					font-size: 2em;
				}
			}

			.WelcomeContainer__right_items {
				width: 100%;

				.WelcomeContainer__right_items__image {
					width: 100%;
					height: 30%;
				}
			}
		}
	}
}