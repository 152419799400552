@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4lM3CwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4rQwCwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo/v21/4UaZrEtFpBI4f1ZSIK9d4LjJ4vowCwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8j4PKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jvvKcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/exo2/v21/7cH1v4okm5zmbvwkAx_sfcEuiD8jWfWcPg.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
* {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  outline: none;
  font-family: 'Exo 2', Arial, Tahoma, Verdana, sans-serif;
  line-height: 140%;
}
html {
  height: 101%;
  font-size: 16px;
  scroll-behavior: smooth;
}
/* always display scrollbars */
body {
  font-size: 62.5%;
  line-height: 1;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
  padding-left: 25px;
  position: relative;
}
ol li,
ul li {
  position: relative;
}
ol li:before,
ul li:before {
  content: '';
  background-size: contain;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -26px;
  top: 6px;
  background: url("../img/icons/play.svg") no-repeat 100% 100%;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
}
strong {
  font-weight: bold;
}
input {
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
  max-width: 100%;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Block {
  width: 100%;
}
.Block:not(.maxWidth) {
  width: 114em;
}
.Block:not(:first-child) {
  margin-bottom: 10em;
}
.Block:last-child {
  margin-bottom: 0;
}
.app.phone {
  overflow: hidden;
  font-size: 0.65em;
}
.app.phone .content {
  padding-top: 12em;
}
.app.phone .Block:not(.maxWidth) {
  width: 100%;
}
.app.phone .Block:not(.maxWidth) .Block__content {
  padding: 0 2em;
}
.app.phone .Block:last-child {
  margin-bottom: 5em;
}
.Icon {
  width: 1.5em;
  height: 1.5em;
}
.Icon .Icon__content {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100%;
}
.Icon.download .Icon__content {
  background-image: url("../img/icons/download.svg");
}
.Icon.play .Icon__content {
  background-image: url("../img/icons/play.svg");
}
.Icon.chat .Icon__content {
  background-image: url("../img/icons/chat.svg");
}
.Icon.key .Icon__content {
  background-image: url("../img/icons/key.svg");
}
.Icon.handshake .Icon__content {
  background-image: url("../img/icons/handshake.svg");
}
.Icon.category .Icon__content {
  background-image: url("../img/icons/category.svg");
}
.Icon.category-2 .Icon__content {
  background-image: url("../img/icons/category-2.svg");
}
.Icon.support .Icon__content {
  background-image: url("../img/icons/support.svg");
}
.Icon.lightning .Icon__content {
  background-image: url("../img/icons/lightning.svg");
}
.Icon.table-file .Icon__content {
  background-image: url("../img/icons/table-file.svg");
}
