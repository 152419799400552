.AccordionItem {
	border-top: 1px solid #CED3D9;
	padding: 4.8em 0;

	&:last-child {
		border-bottom: 1px solid #CED3D9;
	}

	.button {
		font-size: 1.8em;
		font-weight: 700;
		background: none;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:hover {
			cursor: pointer;
		}

		.control {
			font-size: 35px;
			line-height: 20px;
			transition: ease 0.2s;
			height: 11px;
			width: 16px;
		}
	}


	&.clicked {
		.control {
			transform: rotate(180deg);
		}
	}

	&__answer_wrapper {
		height: 0;
		overflow: hidden;
		transition: height ease 0.2s;
	}

	&__answer {
		font-size: 1.8em;
		font-family: "Montserrat", serif;
		white-space: pre-wrap;
	}
}

.phone {
	.AccordionItem {
		font-size: 1.3em;
	}
}