.Icon {
	width: 1.5em;
	height: 1.5em;

	.Icon__content {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
		width: 100%;
		height: 100%;
	}

	&.download {
		.Icon__content {
			background-image: url("../img/icons/download.svg");
		}
	}

	&.play {
		.Icon__content {
			background-image: url("../img/icons/play.svg");
		}
	}

	&.chat {
		.Icon__content {
			background-image: url("../img/icons/chat.svg");
		}
	}

	&.key {
		.Icon__content {
			background-image: url("../img/icons/key.svg");
		}
	}

	&.handshake {
		.Icon__content {
			background-image: url("../img/icons/handshake.svg");
		}
	}

	&.category {
		.Icon__content {
			background-image: url("../img/icons/category.svg");
		}
	}

	&.category-2 {
		.Icon__content {
			background-image: url("../img/icons/category-2.svg");
		}
	}

	&.support {
		.Icon__content {
			background-image: url("../img/icons/support.svg");
		}
	}

	&.lightning {
		.Icon__content {
			background-image: url("../img/icons/lightning.svg");
		}
	}

	&.table-file {
		.Icon__content {
			background-image: url("../img/icons/table-file.svg");
		}
	}
}